import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Dashboard() {
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]); // Список всех объектов
    const [filteredObjects, setFilteredObjects] = useState([]); // Список отфильтрованных объектов
    const [searchTerm, setSearchTerm] = useState(''); // Строка поиска
    const [sid, setSid] = useState(null); // Объявляем sid с помощью useState
    const [au, setAu] = useState(''); // Строка имени пользователя
    const [selectedObjectId, setSelectedObjectId] = useState(null); // ID выбранного объекта
    const [selectedObjectName, setSelectedObjectName] = useState(null); // Название выбранного объекта
    const [dateTimeFrom, setDateTimeFrom] = useState(''); //дата-время с
    const [dateTimeTo, setDateTimeTo] = useState(''); //дата-время по
    const [resourceId, setResourceId] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [currentObjectName, setCurrentObjectName] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [isPreSelectedVisible, setIsPreSelectedVisible] = useState(false);
    const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(false);
    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    /*const [token, setToken] = useState("");*/
    const [error, setError] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [username, setUsername] = React.useState(localStorage.getItem('userName') || '');
    const [reportHeaders, setReportHeaders] = useState([]);
    const [reportRows, setReportRows] = useState([]);
    /*const [tableHeader, setTableHeader] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [actualMileage, setActualMileage] = useState(0);
    const [spent, setSpent] = useState(0);
    const [plannedMileage, setPlannedMileage] = useState(0);*/
    // const [loadedImages, setLoadedImages] = useState({}); // Словарь для отслеживания загруженных изображений

    /*useEffect(() => {
      const inputToken = prompt("Введите ваш токен:");
      if (inputToken) {
        setToken(inputToken);
        fetchSessionId(inputToken);
      }
    },[]);*/

    useEffect(() => {
        // Проверяем наличие токена при монтировании компонента
        if (!localStorage.getItem('userToken')) {
            navigate('/login'); // Если токен отсутствует, перенаправляем на страницу входа
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('userToken'); // Удаление токена из хранилища
        localStorage.removeItem('userName'); // Опционально: удаление имени пользователя
        navigate('/login'); // Перенаправление на страницу входа
    };

    // Функция нажатия по имени компании, вызывающее выпадающий список
    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Функция для отправки запроса к Wialon API и получения значения eid
    const fetchSessionId = async () => {
        const token = localStorage.getItem('userToken');
        const username = localStorage.getItem('userName');
        const apiUrl = `/wialon/ajax.html?svc=token/login&params={"token":"${token}","fl":4}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            const data = await response.json();

            if (data && data.eid) {
                setSid(data.eid); // Устанавливаем значение sid
                setAu(data.au);
                await fetchObjectsList(data.eid);
            } else {
                setError('Ошибка авторизации. Проверьте введенные данные.');
                console.error('Ошибка авторизации:', data);
            }
        } catch (error) {
            console.error('Ошибка при запросе к API');
        }
    };

    /*useEffect(() => {
      const fetchImages = async () => {
        const updatedObjects = await Promise.all(
            objects.map(async (obj) => {
              if (!loadedImages[obj.id]) {
                const imageSvg = await loadObjectImage(obj.id);
                setLoadedImages((prevImages) => ({
                  ...prevImages,
                  [obj.id]: true
                }));
                return {
                  ...obj,
                  imageSvg: imageSvg
                };
              } else {
                return obj;
              }
            })
        );
        setObjects(updatedObjects);
      };

      if (sid) {
        fetchImages();
      }
    }, [objects, sid, loadedImages]);*/

    // Эффект, выполняющийся один раз после монтирования компонента для получения eid
    useEffect(() => {
        fetchSessionId(); // Вызываем функцию отправки запроса при монтировании
    }, []); // Пустой массив зависимостей для запуска эффекта один раз

    // Эффект для периодической отправки запроса на обновление сессии каждые 5 минут
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (sid) {
                renewSession(sid); // Передаем sid в функцию обновления сессии
            } else {
                console.error('SID не определен');
            }
        }, 290000); // 5 минут (почти)

        // Остановка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [sid]);

    // Отображение таблицы, когда данные и выбранный объект готовы
    /*useEffect(() => {
      if (currentObjectName && tableHeader.length > 0 && tableRows.length > 0) {
        displayReportTable(tableHeader, tableRows);
      }
    }, [currentObjectName, tableHeader, tableRows]); // Зависимости для вызова*/

    //Функция для получения списка объектов
    const fetchObjectsList = async (sid) => {
        const listApiUrl = `/wialon/ajax.html?sid=${sid}&svc=core/search_items&params={"spec": {"itemsType": "avl_unit", "propName": "rel_user_creator_name", "propValueMask": "${au}", "sortType": "sys_name"}, "force": 1, "flags": 1, "from": 0, "to": 0}`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        try {
            const response = await fetch(listApiUrl, requestOptions);
            const data = await response.json();

            if (data && data.items) {
                const objectsList = data.items.map((item) => {
                    return {
                        id: item.id,
                        nm: item.nm
                    };
                });
                setObjects(data.items); // Сохраняем список всех объектов
                setFilteredObjects(data.items); // Начально показываем все объекты
                //Запрос на ID ресурса
                await fetchResourceId(sid);
            } else {
                console.error('Ответ не содержит список объектов');
            }
        } catch (error) {
            console.error('Ошибка при запросе к API для списка объектов:', error);
        }
    };


    // Функция для отправки запроса на обновление сессии
    const renewSession = async (sid) => {
        const renewUrl = `/avl_evts?sid=${sid}`;
        const requestOptions = {
            method: 'GET'
        };

        try {
            const response = await fetch(renewUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`Failed to renew session: ${response.status} ${response.statusText}`);
            }
            console.log('Session renewed successfully');
        } catch (error) {
            console.error('Error renewing session:', error.message);
        }
    };

    // Функция для отправки запроса на изображения объектов
    /*const loadObjectImage = async (id) => {
      const imageUrl = `/avl_item_image/${id}/32?b=0&v=0&sid=${sid}`;

      try {
        const response = await fetch(imageUrl);
        const imageBlob = await response.blob();
        const imageUrlObject = URL.createObjectURL(imageBlob);
        console.log(`Изображение для объекта с ID ${id} успешно загружено.`);
        return imageUrlObject;
      } catch (error) {
        console.error(`Ошибка при загрузке изображения для объекта с ID ${id}:`, error);
        return null;
      }
    };*/

    // Функция для обработки клика по объекту списка
    const handleObjectClick = (objectId, objectName) => {
        setSelectedObjectId(objectId); // Устанавливаем выбранный объект
        setSelectedObjectName(objectName); // Устанавливаем название выбранного объекта
        setIsPreSelectedVisible(true);
    };

    //Функция поиска по списку объектов
    const handleSearchChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = objects.filter(
            (object) => object.nm.toLowerCase().includes(searchTerm)
        );
        setFilteredObjects(filtered); // Обновляем список отфильтрованных объектов
    };

    //Функция для поиска ID ресурса"
    const fetchResourceId = async (sid) => {
        const resourceApiUrl = `/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_resource","propName":"reporttemplates","propValueMask":"Путевой*","sortType":"sys_name","propType":"propitemname"},"force":1,"flags":8193,"from":0,"to":0}&sid=${sid}`;
        const resourceRequestOptions = {
            method: 'GET'
        };

        try {
            const response = await fetch(resourceApiUrl, resourceRequestOptions);
            const data = await response.json();
            if (data && data.items && data.items.length > 0) {
                const resourceId = data.items[0].id;
                setResourceId(resourceId);
                const reports = data.items[0].rep;
                for (const key in reports) {
                    if (reports[key].n.includes("Путевой")) {
                        const reportId = reports[key].id;
                        setReportId(reportId);
                        break;
                    } else {
                        console.error('Не удалось найти ID отчета в ресурсе');
                    }
                }
            } else {
                console.error('Не удалось найти ресурс');
            }
        } catch (error) {
            console.error('Ошибка при запросе: ', error);
        }
    }

    //Функция нажатия кнопки
    async function handleFindButtonClick(){
        if (!selectedObjectName || !dateTimeFrom || !dateTimeTo|| new Date(dateTimeFrom) >= new Date(dateTimeTo)) {
            alert("Проверьте, что все поля заполнены корректно и дата начала меньше даты окончания.");
            return;
        }
        setIsDownloadButtonVisible(false);
        setIsLoading(true);
        setSelectedObjectId(selectedObjectId);
        setCurrentObjectName(selectedObjectName);

        // Преобразование дат в формат UNIX timestamp (миллисекунды в секунды)
        const from = Math.floor(new Date(dateTimeFrom).getTime() / 1000);
        const to = Math.floor(new Date(dateTimeTo).getTime() / 1000);
        const cleanupUrl = `/wialon/ajax.html?sid=${sid}&svc=report/cleanup_result`;
        const requestOptions = {
            method: 'GET'
        };
        try {
            await fetch(cleanupUrl, requestOptions);

            const execReportUrl = `/wialon/ajax.html?sid=${sid}&svc=report/exec_report&params={"reportResourceId":${resourceId},"reportTemplateId":${reportId},"reportObjectId":${selectedObjectId},"reportObjectSecId":0,"interval":{"from":${from},"to":${to},"flags":0}}`;
            const execResponse = await fetch(execReportUrl);
            const execData = await execResponse.json();

            if (execData && execData.reportResult) {
                if (execData.reportResult.tables.length > 0) {
                    const header = execData.reportResult.tables[0].header;
                    const rowsCount = execData.reportResult.tables[0].rows;

                    const getResultRowsUrl = `/wialon/ajax.html?sid=${sid}&svc=report/get_result_rows&params={"tableIndex":0,"indexFrom":0,"indexTo":${rowsCount}}`;
                    const rowsResponse = await fetch(getResultRowsUrl);
                    const rowsData = await rowsResponse.json();

                    setIsLoading(false);
                    setReportHeaders(header);
                    setReportRows(rowsData);
                    displayReportData(header, rowsData);
                    /*// Отправка данных на сервер для создания путевого листа
                    await createTripSheet(header, rowsData);*/

                } else {
                    setIsLoading(false);
                    setReportData("По выбранному автомобилю нет данных за указанный период");
                    setIsDownloadButtonVisible(false);
                    setIsDownloadButtonDisabled(true);
                }
                setCurrentObjectName(selectedObjectName);
            }
        } catch (error) {
            console.error('Ошибка при запросе');
        }
    }

    function displayReportData(header, rowsData) {
        if (rowsData.length === 0) {
            /*setActualMileage("По выбранному автомобилю нет данных");
            setSpent("По выбранному автомобилю нет данных");
            setPlannedMileage("По выбранному автомобилю нет данных");*/
            setReportData("По выбранному автомобилю нет данных");
            setIsDownloadButtonVisible(false);
            setIsDownloadButtonDisabled(true);
        } else {
            const actualMileageIndex = header.findIndex(col => col === "Фактический пробег");
            const spentIndex = header.findIndex(col => col === "Потрачено");
            const plannedMileageIndex = header.findIndex(col => col === "Планируемый пробег");

            const actualMileageSum = rowsData.reduce((sum, row) => sum + parseFloat(row.c[actualMileageIndex] || 0), 0);
            const spentSum = rowsData.reduce((sum, row) => sum + parseFloat(row.c[spentIndex] || 0), 0);
            const plannedMileageSum = rowsData.reduce((sum, row) => sum + parseFloat(row.c[plannedMileageIndex] || 0), 0);

            /*setActualMileage(actualMileageSum);
            setSpent(spentSum);
            setPlannedMileage(plannedMileageSum);*/
            setReportData({
                actualMileage: actualMileageSum.toFixed(2),
                spent: spentSum.toFixed(2),
                plannedMileage: plannedMileageSum.toFixed(2)
            });
            setIsDownloadButtonVisible(true);
            setIsDownloadButtonDisabled(false);
        }
    }

    async function createTripSheet() {
        if (!reportHeaders || !reportRows || reportHeaders.length === 0 || reportRows.length === 0) {
            console.error("Header или rowsData отсутствуют");
            return;
        }

        // Найдем индексы необходимых столбцов
        const plannedDepartureIndex = reportHeaders.findIndex(col => col === "Планируемое время выхода");
        const plannedArrivalIndex = reportHeaders.findIndex(col => col === "Планируемое время прибытия");

        if (plannedDepartureIndex === -1 || plannedArrivalIndex === -1) {
            console.error("Необходимые данные не найдены в отчете");
            return;
        }

        // Получим первую строку данных отчета
        const firstRow = reportRows[0];
        const lastRow = reportRows[reportRows.length - 1];

        // Извлечем планируемое время выхода и прибытия
        const plannedDepartureTime = firstRow.c[plannedDepartureIndex];
        const plannedArrivalTime = lastRow.c[plannedArrivalIndex];

        if (!plannedDepartureTime || !plannedArrivalTime) {
            console.error("Планируемое время отсутствует в данных");
            return;
        }

        // Извлечем день, месяц, часы и минуты из планируемого времени выхода и прибытия
        const departureDate = new Date(plannedDepartureTime);
        const arrivalDate = new Date(plannedArrivalTime);

        const plannedDepartureDay = departureDate.getDate();
        const plannedDepartureMonth = departureDate.toLocaleString('ru', { month: 'long' });
        const plannedDepartureHour = departureDate.getHours();
        const plannedDepartureMinute = departureDate.getMinutes();

        const plannedArrivalDay = arrivalDate.getDate();
        const plannedArrivalMonth = arrivalDate.toLocaleString('ru', { month: 'long' });
        const plannedArrivalHour = arrivalDate.getHours();
        const plannedArrivalMinute = arrivalDate.getMinutes();

        try {
            const response = await fetch('/api/download-trip-sheet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    plannedDepartureDay,
                    plannedDepartureMonth,
                    plannedDepartureHour,
                    plannedDepartureMinute,
                    plannedArrivalDay,
                    plannedArrivalMonth,
                    plannedArrivalHour,
                    plannedArrivalMinute
                })
            });

            if (!response.ok) {
                throw new Error('Ошибка при генерации путевого листа');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'trip-sheet.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Ошибка при запросе к API:', error);
        }
    }


    /*// Обновление таблицы при получении новых данных
    function displayReportTable(header, rowsData) {
      const tableContainer = document.getElementById("report-table");
      if (tableContainer) {
        tableContainer.innerHTML = ""; // Очистка предыдущих результатов
        const table = document.createElement("table");
        const thead = document.createElement("thead");
        const tbody = document.createElement("tbody");

        // Создание заголовка таблицы
        const headerRow = document.createElement("tr");
        header.forEach(head => {
          const th = document.createElement("th");
          th.textContent = head;
          headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);

        // Добавление строк в таблицу
        rowsData.forEach(row => {
          const tr = document.createElement("tr");
          row.c.forEach(cell => {
            const td = document.createElement("td");
            td.textContent = cell;
            tr.appendChild(td);
          });
          tbody.appendChild(tr);
        });

        // Сборка и отображение таблицы
        table.appendChild(thead);
        table.appendChild(tbody);
        tableContainer.appendChild(table); // Добавление сформированной таблицы в DOM
      } else {
        console.error('Element #report-table not found');
      }
    }*/

    // Возвращаем JSX, отображающий значение eid или загрузку
    return (
        <div className="Dashboard">
            <div className="upper-bar">
                <div className="buttons-bar">
                    <div className="background-highlight"></div>
                    <button
                        className="button-rout-left"
                    >Путевой лист
                    </button>
                    <button
                        className="button-rout"
                    >Будущее приложение
                    </button>
                    <button
                        className="button-rout"
                    >Будущее приложение
                    </button>
                    <button
                        className="button-rout"
                    >Будущее приложение
                    </button>
                </div>

                <div className="account-section">
                    <button
                        className={`button-abandon-account ${showDropdown ? 'move-left' : ''}`}
                        onClick={handleToggleDropdown}
                    >
                        {username}
                    </button>
                    <div className={`account-dropdown-menu ${showDropdown ? 'show-dropdown' : ''}`}>
                        <button
                            className="account-dropdown-item"
                            onClick={handleLogout}
                        >Выйти
                        </button>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="sidebar">
                    <div className="topLabel">
                        <div className="tempLogo">
                            <svg width="100" height="74" viewBox="0 0 124 92" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M36.67 35.57H38.55V33.51H46.47V35.57H77.64V33.51H85.56V35.57H87.44C90.7 35.57 93.37 38.24 93.37 41.5V54.73C93.37 57.99 90.7 60.66 87.44 60.66H85.56V62.72H77.64V60.66H46.47V62.72H38.55V60.66H36.67C33.41 60.66 30.74 57.99 30.74 54.73V41.5C30.74 38.24 33.41 35.57 36.67 35.57ZM93.81 12H93.98C100.41 12 106.23 14.61 110.45 18.82C114.66 23.04 117.27 28.86 117.27 35.29V35.4H114.4V35.29C114.4 29.65 112.11 24.55 108.42 20.85C104.73 17.16 99.62 14.87 93.98 14.87H93.81V12ZM93.99 20.18C98.16 20.18 101.94 21.88 104.67 24.61C107.4 27.35 109.09 31.12 109.09 35.29V35.31H106.22V35.29C106.22 31.91 104.85 28.84 102.64 26.63C100.43 24.41 97.37 23.04 93.99 23.04V20.18ZM93.98 27.49C96.13 27.49 98.08 28.37 99.49 29.78C100.9 31.19 101.77 33.14 101.77 35.29V35.31H98.9V35.29C98.9 33.93 98.35 32.69 97.46 31.8C96.57 30.91 95.34 30.35 93.98 30.35V27.49ZM94.26 4.79001H29.84C13.43 4.79001 0 18.21 0 34.62V61.61C0 78.02 13.43 91.45 29.84 91.45H94.27C110.68 91.45 124.11 78.02 124.11 61.61V34.62C124.1 18.21 110.67 4.79001 94.26 4.79001Z"
                                      fill="#54adff">
                                </path>
                            </svg>
                        </div>
                        <div className="header">
                            <h1>Orion Tracking</h1>
                            <h2>Путевой лист</h2>
                        </div>
                    </div>
                    <div className="voke">
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Поиск..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="object-list">
                            {filteredObjects.map((object) => (
                                <div
                                    key={object.id}
                                    className={`object-item ${object.id === selectedObjectId ? 'selected' : ''}`}
                                    onClick={() => handleObjectClick(object.id, object.nm)}>
                                    <p>{object.nm}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="main-content">
                    <div className="picker">
                        {/*На случай, если надо скрыть элемент до появления в нем текста*/}
                        {/*{selectedObjectName && (
                <div className="pre-selected">
                  <span className="pre-selected-view">{selectedObjectName}</span>
                </div>
              )}*/}
                        {isPreSelectedVisible && (
                            <div className="pre-selected">
                                <span className="pre-selected-view">{selectedObjectName}</span>
                            </div>
                        )}
                        <div className="date-time-picker-from">
                            <input
                                type="datetime-local"
                                id="date-time-picker-from"
                                name="date-time-picker-from"
                                value={dateTimeFrom}
                                onChange={(e) => setDateTimeFrom(e.target.value)}/>
                        </div>
                        <h4>-</h4>
                        <div className="date-time-picker-to">
                            <input
                                type="datetime-local"
                                id="date-time-picker-to"
                                name="date-time-picker-to"
                                value={dateTimeTo}
                                onChange={(e) => setDateTimeTo(e.target.value)}/>
                        </div>
                        <button className="find_button" onClick={handleFindButtonClick}>
                            <span className="find_button_view">Найти</span>
                        </button>
                    </div>
                    {currentObjectName && (
                        <div className="selected-object">
                            <h3>{currentObjectName}</h3>
                            <div className="report-view">
                                {isLoading ? (
                                    <div className="loading-animation">
                                        <div className="svg-background">
                                            <svg
                                                width="210px"
                                                height="145px"
                                                viewBox="0 0 210 145"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M 139.80584,27.946071 H 68.700958 c -18.111689,0 -32.93115,14.819633 -32.93115,32.931311 v 29.784224 c 0,18.111674 14.818811,32.930824 32.93115,32.930824 h 71.104882 c 18.11234,0 32.93129,-14.81882 32.93129,-32.930824 V 60.877382 c 0,-18.112334 -14.81965,-32.931311 -32.93129,-32.931311 z"
                                                    style={{
                                                        fill: "#5dabf6",
                                                        fillOpacity: "1",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "1.63799"
                                                    }}/>
                                            </svg>
                                        </div>
                                        <div className="svg-waves">
                                            <svg
                                                width="576px"
                                                height="395px"
                                                viewBox="13.9 -9 210 145"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="m 131.24179,48.019669 h -25.00132 c -6.368291,0 -11.57899,5.21076 -11.57899,11.579044 v 10.47249 c 0,6.368283 5.210477,11.578871 11.57899,11.578871 h 25.00132 c 6.36851,0 11.57903,-5.210474 11.57903,-11.578871 v -10.47249 c 0,-6.368514 -5.21075,-11.579044 -11.57903,-11.579044 z"
                                                    style={{
                                                        fill: "#282937",
                                                        fillOpacity: "1",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "0.57594"
                                                    }}/>
                                                <path
                                                    d="m 131.66966,47.444122 h -25.85707 c -6.586253,0 -11.975305,5.389113 -11.975305,11.975369 v 10.83094 c 0,6.586255 5.388814,11.97519 11.975305,11.97519 h 25.85707 c 6.58649,0 11.97536,-5.388817 11.97536,-11.97519 v -10.83094 c 0,-6.586494 -5.3891,-11.975369 -11.97536,-11.975369 z m -0.18269,2.894518 h 0.0678 c 2.58084,0 4.91782,1.046561 6.6096,2.738156 1.69159,1.691716 2.73815,4.028761 2.73815,6.609606 v 0.04425 h -1.15318 v -0.04425 c 0,-2.262647 -0.91742,-4.311396 -2.40024,-5.794335 -1.483,-1.482877 -3.53169,-2.400303 -5.79433,-2.400303 h -0.0678 z m 0.0718,3.282106 c 1.67492,0.0012 3.19109,0.680774 4.28816,1.778621 1.09726,1.097967 1.77588,2.613966 1.77588,4.287035 v 0.0079 h -1.15312 v -0.0079 c 0,-1.358089 -0.54949,-2.587218 -1.43803,-3.476351 -0.88865,-0.88919 -2.11665,-1.439811 -3.47289,-1.440705 z m -5.9e-4,2.936095 c 0.86429,0.0012 1.64668,0.352089 2.21243,0.918557 0.56611,0.566823 0.91617,1.348499 0.91617,2.211004 v 0.0078 h -1.15318 v -0.0078 c 0,-0.547464 -0.22093,-1.042511 -0.57826,-1.40026 -0.35757,-0.358048 -0.85179,-0.579989 -1.39716,-0.580704 z"
                                                    style={{
                                                        fill: "#5dabf6",
                                                        fillOpacity: "0.995868",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "0.595653"
                                                    }}/>
                                            </svg>
                                        </div>
                                        <div className="svg-car">
                                            <svg
                                                width="165px"
                                                height="141px"
                                                viewBox="-1 8 210 145"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="m 68.762716,67.127127 h 69.084024 c 4.4398,0 8.07248,3.631728 8.07248,8.071897 v 18.008753 c 0,4.439359 -3.63268,8.071283 -8.07248,8.071283 H 68.762716 c -4.439763,0 -8.071892,-3.631924 -8.071892,-8.071283 V 75.199024 c 0,-4.440169 3.632129,-8.071897 8.071892,-8.071897 z"
                                                    style={{
                                                        fill: "#282937",
                                                        fillOpacity: "1",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "2.0201"
                                                    }}/>
                                                <path
                                                    d="m 124.51674,104.08901 h 10.7778 V 64.316974 h -10.7778 z"
                                                    style={{
                                                        fill: "#282937",
                                                        fillOpacity: "1",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "2.0201"
                                                    }}/>
                                                <path
                                                    d="M 71.315116,104.08901 H 82.092925 V 64.316974 H 71.315116 Z"
                                                    style={{
                                                        fill: "#282937",
                                                        fillOpacity: "1",
                                                        fillRule: "evenodd",
                                                        strokeWidth: "2.0201"
                                                    }}/>
                                            </svg>
                                        </div>
                                    </div>
                                ) : reportData ? (
                                    typeof reportData === 'string' ? (
                                        <p>{reportData}</p>
                                    ) : (
                                        <div>
                                            <p>Планируемый пробег: {reportData.plannedMileage} км</p>
                                            <p>Фактический пробег: {reportData.actualMileage} км</p>
                                            <p>Расход: {reportData.spent} литров</p>
                                        </div>
                                    )
                                ): (
                                    <p>Данные для отображения отсутствуют</p>
                                )}

                                {/*{currentObjectName && (
                          <div>
                            <h3>Отчет для объекта: {currentObjectName}</h3>
                            <div id="report-table"></div>  Сюда будет вставлена таблица
                          </div>
                      )}*/}
                                {/*<p>Планируемый пробег: {plannedMileage} км</p>
                    <p>Фактический пробег: {actualMileage} км</p>
                    <p>Расход: {spent} литров</p>*/}
                            </div>
                        </div>
                    )}
                    {isDownloadButtonVisible && (
                        <button
                            className="download-report"
                            disabled={isDownloadButtonDisabled}
                            onClick={createTripSheet}
                            /*onClick={handleDownloadReport}*/
                        >
                            Скачать путевой лист
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;