import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Login.css"

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Перенаправляем пользователя на Dashboard, если токен уже существует
        if (localStorage.getItem('userToken')) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleLogin = async () => {
        try {
            const response = await fetch('https://test-dev.orion-tracking.fvds.ru/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('userName', data.username);
                navigate('/dashboard');
            } else {
                alert('Неверные учетные данные');
            }
        } catch (error) {
            console.error('Ошибка при авторизации:', error);
            alert('Ошибка при авторизации');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="Login">
            <div className="background-container"></div>
            <div className="auth-field">
                <div className="login-header">
                    <svg width="62.06" height="43.33" viewBox="0 0 124 92" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M36.67 35.57H38.55V33.51H46.47V35.57H77.64V33.51H85.56V35.57H87.44C90.7 35.57 93.37 38.24 93.37 41.5V54.73C93.37 57.99 90.7 60.66 87.44 60.66H85.56V62.72H77.64V60.66H46.47V62.72H38.55V60.66H36.67C33.41 60.66 30.74 57.99 30.74 54.73V41.5C30.74 38.24 33.41 35.57 36.67 35.57ZM93.81 12H93.98C100.41 12 106.23 14.61 110.45 18.82C114.66 23.04 117.27 28.86 117.27 35.29V35.4H114.4V35.29C114.4 29.65 112.11 24.55 108.42 20.85C104.73 17.16 99.62 14.87 93.98 14.87H93.81V12ZM93.99 20.18C98.16 20.18 101.94 21.88 104.67 24.61C107.4 27.35 109.09 31.12 109.09 35.29V35.31H106.22V35.29C106.22 31.91 104.85 28.84 102.64 26.63C100.43 24.41 97.37 23.04 93.99 23.04V20.18ZM93.98 27.49C96.13 27.49 98.08 28.37 99.49 29.78C100.9 31.19 101.77 33.14 101.77 35.29V35.31H98.9V35.29C98.9 33.93 98.35 32.69 97.46 31.8C96.57 30.91 95.34 30.35 93.98 30.35V27.49ZM94.26 4.79001H29.84C13.43 4.79001 0 18.21 0 34.62V61.61C0 78.02 13.43 91.45 29.84 91.45H94.27C110.68 91.45 124.11 78.02 124.11 61.61V34.62C124.1 18.21 110.67 4.79001 94.26 4.79001Z"
                              fill="#54adff">
                        </path>
                    </svg>
                    <div className="header-text">
                        <h1>Orion Tracking</h1>
                        <h2>Fleet Hub</h2>
                    </div>
                </div>
                <div className="login-fields">
                    <input
                        className="login-fields-input"
                        type="text"
                        placeholder="Логин"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <input
                        className="login-fields-input"
                        type="password"
                        placeholder="Пароль"
                        value={password} onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button
                        className="login-fields-button"
                        onClick={handleLogin}
                    >Войти
                    </button>
                </div>
            </div>

        </div>
    );
}

export default Login;